
import { VBVisible } from "bootstrap-vue"
import { mapGetters } from "vuex"

export const isUndefined = (val) => val === undefined
export const isNull = (val) => val === null
export const isUndefinedOrNull = (val) => isUndefined(val) || isNull(val)
export const isArray = (val) => Array.isArray(val)
export const isPlainObject = (obj) => Object.prototype.toString.call(obj) === "[object Object]"

const toString = (val, spaces = 2) => {
  return isUndefinedOrNull(val) ? "" : isArray(val) || (isPlainObject(val) && val.toString === Object.prototype.toString) ? JSON.stringify(val, null, spaces) : String(val)
}

const hasIntersectionObserverSupport =
  process.client &&
  "IntersectionObserver" in window &&
  "IntersectionObserverEntry" in window &&
  // Edge 15 and UC Browser lack support for `isIntersecting`
  // but we an use intersectionRatio > 0 instead
  // 'isIntersecting' in window.IntersectionObserverEntry.prototype &&
  "intersectionRatio" in window.IntersectionObserverEntry.prototype

// Blank image with fill template
const BLANK_TEMPLATE = '<svg width="%{w}" height="%{h}" ' + 'xmlns="http://www.w3.org/2000/svg" ' + 'viewBox="0 0 %{w} %{h}" preserveAspectRatio="none">' + '<rect width="100%" height="100%" style="fill:%{f};"></rect>' + "</svg>"

const makeBlankImgSrc = (width, height, color) => {
  const src = encodeURIComponent(
    BLANK_TEMPLATE.replace("%{w}", toString(width))
      .replace("%{h}", toString(height))
      .replace("%{f}", color)
  )
  return `data:image/svg+xml;charset=UTF-8,${ src }`
}

export default {
  name: "PugImgSteave",
  directives: {
    bVisible: VBVisible
  },
  props: {
    img: {
      type: Object,
      default: null
    },
    svgHeight: {
      type: String,
      default: "100%"
    },
    svgWidth: {
      type: String,
      default: "100%"
    },
    height: {
      type: [String, Number],
      default: null
    },
    width: {
      type: [String, Number],
      default: null
    },
    fluid: {
      type: Boolean,
      default: false
    },
    fluidGrow: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: () => {
        return ""
      }
    },
    styles: {
      type: [String, Object],
      default: () => {
        return ""
      }
    },
    rounded: {
      type: Boolean,
      default: false
    },
    urlOnly: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    },
    blankColor: {
      type: String,
      default: "transparent"
    },
    show: {
      type: Boolean,
      default: false
    },
    offset: {
      // Distance away from viewport (in pixels) before being
      // considered "visible"
      type: [Number, String],
      default: 100
    },
    // computedBlankSrc: {
    //   // If null, a blank image is generated
    //   type: String,
    //   default: null
    // },
    blankWidth: {
      type: [Number, String],
      default: null
    },
    /**
     * Use this prop to only show one size of the image
     * ex: thumbnail | medium | large
     */
    sizeToShow: {
      type: String,
      default: null
    },
    blankHeight: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      isShown: this.show,
      finalClasses: "",
      baseUrl: 'https://cdn.filestackcontent.com',
      // If we want to load the filestack adaptive script:
      // scriptLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      getSelectedLocation: "clientWebsite/getSelectedLocation"
    }),
    isSrcOnly() {
      if (this.urlOnly) {
        return false
      }

      // Default to the src if no computedImageHandle exists
      if (!this.computedImgHandle) {
        return true
      }

      return false
    },
    computedImgHandle() {
      return this.img?.url?.split('/').pop()
    },
    computedBlankSrc() {
      return makeBlankImgSrc(this.computedWidth, this.computedHeight, this.blankColor || "transparent")
    },
    computedWidth() {
      return this.isShown ? this.width : this.blankWidth || this.width
    },
    computedHeight() {
      return this.isShown ? this.height : this.blankHeight || this.height
    },
    sizeToShowInPixels() {
      if (this.sizeToShow === "thumbnail") {
        return 200
      } else if (this.sizeToShow === "medium") {
        return 400
      } else if (this.sizeToShow === "large") {
        return 1024
      } else {
        return null
      }
    },
    computedSrc() {
      const src = `${ this.baseUrl }${ this.sizeToShowInPixels ? `/resize=width:${ this.sizeToShowInPixels }` : "" }/${ this.computedImgHandle }`
      return this.isShown ? src : this.computedBlankSrc
    },
    computedWebpSrc() {
      const src = `${ this.baseUrl }${ this.sizeToShowInPixels ? `/resize=width:${ this.sizeToShowInPixels }` : "" }/output=format:webp/${ this.computedImgHandle }`
      return this.isShown ? src : this.computedBlankSrc
    },
    computedSrcUrlOnly() {
      const src = `${ this.img.url }`
      return this.isShown ? src : this.computedBlankSrc
    },
    computedSrcset() {
      const srcset = `${ this.baseUrl }/resize=width:400/${ this.computedImgHandle } 400w, ${ this.baseUrl }/resize=width:1024/${ this.computedImgHandle } 1024w, ${ this.baseUrl }/resize=width:1600/${ this.computedImgHandle } 1600w`
      return this.isShown ? srcset : this.computedBlankSrc
    },
    computedWebpSrcset() {
      const srcset = `${ this.baseUrl }/output=format:webp/resize=width:400/${ this.computedImgHandle } 400w, ${ this.baseUrl }/output=format:webp/resize=width:1024/${ this.computedImgHandle } 1024w, ${ this.baseUrl }/output=format:webp/resize=width:1600/${ this.computedImgHandle } 1600w`
      return this.isShown ? srcset : this.computedBlankSrc
    },
    computedAltText() {
      return this.img?.alt || "an image"
    }
  },
  watch: {
    show(newVal, oldVal) {
      // console.log('watch - show: ', newVal, oldVal)
      if (newVal !== oldVal) {
        // If IntersectionObserver support is not available, image is always shown
        const visible = hasIntersectionObserverSupport ? newVal : true
        this.isShown = visible
        if (visible !== newVal) {
          // Ensure the show prop is synced (when no IntersectionObserver)
          this.$nextTick(this.updateShowProp)
        }
      }
    },
    isShown(newVal, oldVal) {
      // console.log('watch - isShown: ', newVal, oldVal)
      if (newVal !== oldVal) {
        // Update synched show prop
        this.updateShowProp()
      }
    },
    scriptLoaded(newVal, oldVal) {
      // console.log('watch - scriptLoaded: ', window)
      const options = {
        alt: 'windsurfer',
        formats: ['webp', 'jpg'], // order matters!
        sizes: {
          fallback: '100vw',
        }
      }
      // console.log(window.fsAdaptive.fsAdaptive.picture(this.computedImgHandle, options))
      // console.log(window.fsAdaptive.makePictureTree(this.computedImgHandle, options))
    }
  },
  mounted() {
    // console.log(this.computedImgHandle)
    // console.log(this.img, `${ this.baseUrl }${ this.sizeToShowInPixels ? `/width=${ this.sizeToShowInPixels }` : "" }/${ this.computedImgHandle }`)
    // If IntersectionObserver is not available, image is always shown
    this.isShown = hasIntersectionObserverSupport ? this.show : true
    // If we want to load the filestack adaptive script:
    // this.loadFilestackAdaptive()
  },
  methods: {
    updateShowProp() {
      this.$emit("update:show", this.isShown)
    },
    doShow(visible) {
      // If IntersectionObserver is not supported, the callback
      // will be called with `null` rather than `true` or `false`
      if ((visible || visible === null) && !this.isShown) {
        this.isShown = true
      }
    },
    // If we want to load the filestack adaptive script:
    // loadFilestackAdaptive() {
    //   if (window.fsAdaptive) {
    //     this.scriptLoaded = true
    //     return
    //   }

    //   let filestackScript = document.createElement('script')
    //   filestackScript.setAttribute('type', 'text/javascript')
    //   filestackScript.setAttribute('crossorigin', 'anonymous')
    //   filestackScript.setAttribute('src', 'https://static.filestackapi.com/adaptive/1.3.0/adaptive.min.js')
    //   filestackScript.addEventListener('load', () => {
    //     this.scriptLoaded = true
    //   })
    //   document.head.appendChild(filestackScript)
    // }
  },
  render(createElement) {
    var props = {}
    const directives = []
    if (!this.isShown) {
      // We only add the visible directive if we are not shown
      directives.push({
        // Visible directive will silently do nothing if
        // IntersectionObserver is not supported
        name: "b-visible",
        // Value expects a callback (passed one arg of `visible` = `true` or `false`)
        value: this.doShow,
        modifiers: {
          // Root margin from viewport
          [`${ parseInt(this.offset, 10) || 0 }`]: true,
          // Once the image is shown, stop observing
          once: true
        }
      })
    }

    if (this.urlOnly === true) {
      props = {
        src: null,
        fluid: this.fluid,
        fluidGrow: this.fluidGrow,
        rounded: this.rounded,
        blank: this.blank,
        blankColor: this.blankColor,
        height: this.height,
        width: this.width
      }
      return createElement("img", {
        props,
        attrs: {
          src: this.computedSrcUrlOnly,
          alt: this.computedAltText,
        },
        class: this.classes,
        style: this.styles,
        directives
      })
    } else if (this.img && this.img.fileType !== "image/svg+xml") {
      props = {
        src: null,
        fluid: this.fluid,
        fluidGrow: this.fluidGrow,
        rounded: this.rounded,
        blank: this.blank,
        blankColor: this.blankColor,
        height: this.height,
        width: this.width
      }

      var children = []

      if (this.img && this.img.url && this.sizeToShow === null) {
        children.push(
          createElement("source", {
            attrs: {
              srcset: this.computedWebpSrcset,
              type: "image/webp"
            }
          })
        )

        props.src = this.img.url
        props.srcset = this.computedSrcset
        props.alt = this.img.alt
        props.title = this.img.title
        children.push(
          createElement("b-img", {
            props,
            class: this.classes,
            style: this.styles
          })
        )

        return createElement("picture", { directives }, children)
      } else if (this.img && this.img.url && this.sizeToShow !== null) {
        children.push(
          createElement("source", {
            attrs: {
              srcset: this.computedWebpSrc,
              type: "image/webp"
            },
            class: this.classes,
            style: this.styles
          })
        )

        props.src = this.computedSrc
        props.alt = this.img.alt
        props.title = this.img.title
        children.push(
          createElement("b-img", {
            props,
            class: this.classes,
            style: this.styles
          })
        )

        return createElement("picture", { directives }, children)
      } else {
        return createElement("b-img", {
          directives,
          props,
          class: this.classes,
          style: this.styles
        })
      }
    } else if (this.img && this.img.fileType === "image/svg+xml") {
      props = {
        src: this.img.url,
        alt: this.img.alt,
        title: this.img.title,
        width: this.svgWidth,
        height: this.svgHeight
      }
      return createElement(
        "div",
        {
          directives,
          style: {
            width: this.svgWidth,
            height: this.svgHeight
          }
        },
        [
          createElement("simple-svg", {
            props,
            class: this.classes,
            style: this.styles
          })
        ]
      )
    } else {
      props.src = this.img
      return createElement("b-img-lazy", {
        props,
        class: this.classes,
        style: this.styles
      })
    }
  }
}
