
import { mapGetters } from "vuex"
import PugImgSteave from "@/dashboard/pug-img/PugImgSteave"
import PugImgInsights from "@/dashboard/pug-img/PugImgInsights"

export default {
  name: "PugImg",
  components: {
    PugImgSteave,
    PugImgInsights
  },
  props: {
    img: {
      type: Object,
      default: null
    },
    svgHeight: {
      type: String,
      default: "100%"
    },
    svgWidth: {
      type: String,
      default: "100%"
    },
    height: {
      type: [String, Number],
      default: null
    },
    width: {
      type: [String, Number],
      default: null
    },
    fluid: {
      type: Boolean,
      default: false
    },
    fluidGrow: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: () => {
        return ""
      }
    },
    styles: {
      type: [String, Object],
      default: () => {
        return ""
      }
    },
    rounded: {
      type: Boolean,
      default: false
    },
    urlOnly: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    },
    blankColor: {
      type: String,
      default: "transparent"
    },
    show: {
      type: Boolean,
      default: false
    },
    offset: {
      // Distance away from viewport (in pixels) before being
      // considered "visible"
      type: [Number, String],
      default: 100
    },
    // computedBlankSrc: {
    //   // If null, a blank image is generated
    //   type: String,
    //   default: null
    // },
    blankWidth: {
      type: [Number, String],
      default: null
    },
    /**
     * Use this prop to only show one size of the image
     * ex: medium | large
     */
    sizeToShow: {
      type: String,
      default: null
    },
    blankHeight: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isSteaveAccount: "accounts/getIsSteaveAccount",
    }),
    isUseSteave() {
      // Check if the image is a steave image
      if (this.img && (this.img.src || this.img.url)) {
        const imgUrl = this.img.src ?? this.img.url
        // If the image is a steave image, it will have a filestack url. Otherwise,
        // it is an Insights image, and it should use the Insights component.
        if (imgUrl.includes("filestackcontent.com")) {
          return true
        }

        return false
      }

      // Fallback to checking on the account type
      return this.isSteaveAccount
    }
  }
}
